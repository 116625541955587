import tutorBecome from '../../Assets/layout_images/tutorBecome.svg'
import arrowTutor from '../../Assets/layout_icons/arrowTutor.svg'
import { Container, Grid } from '@material-ui/core'

export default function BecomeTutor() {
    const becomeTutorPoints = [
        'Tutors will be paid on time',
        'Tutors must use DigiBoard or white Board to tutor students',
        'Tutors are selected only after a stringent interview process',
        'Tutor must select a time of study with mutual agreement with the student. Our office admins will take care of arranging classes',
        'Tutors should provide a Free Demo Class for every new student, to determine if both tutor and the student are comfortable with each other',
        'Tutors must take as many classes are requested by the students'
    ]
    return (
        <div className="become_tutor_section">
            <h2 className="font-Quick font-semi">
                Become a Tutor at TeacherInd
            </h2>
            <div className="tutor_become_image">
                <img src={tutorBecome} alt="tutorBecome" />
            </div>
            <div className="become_tutor_points_wrapper">
                <Container maxWidth="md">
                    <Grid container spacing={3} justify="center">
                        {becomeTutorPoints.map((el, key) => (
                            <Grid item xs={11} md={6} key={key}>
                                <div className="become_tutor_points">
                                    <img src={arrowTutor} alt="arrowTutor" />
                                    <p className="font-Quick font-med">{el}</p>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
        </div>
    )
}
