import { Tab, Tabs } from '@material-ui/core'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminPanelTemplate from '../templates/AdminPanelTemplate'
import LeadTable from '../partialComponents/LeadTable'
import { DB } from '../../firebase'

function Leads() {
    const [tabNo, setTabNo] = useState(0)
    const [newLeads, setNewLeads] = useState([])
    const [completedLeads, setCompletedLeads] = useState([])
    const [successLeads, setSuccessLeads] = useState([])
    const [rejectedLeads, setRejectedLeads] = useState([])

    const handleTabChange = (event, newtabNo) => {
        setTabNo(newtabNo)
    }

    function getNewLeads() {
        console.log('Getting New Leads')
        DB.collection('leads')
            .where('completed', '==', false)
            .where('confirmed', '==', false)
            .where('rejected', '==', false)
            .orderBy('timestamp', 'desc')
            .onSnapshot((querySnapshot) => {
                var leads = []
                querySnapshot.forEach((doc) => {
                    leads.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setNewLeads(leads)
            })
    }

    function getCompletedLeads() {
        console.log('Getting Completed Leads')
        DB.collection('leads')
            .where('completed', '==', true)
            .where('confirmed', '==', false)
            .where('rejected', '==', false)
            .orderBy('timestamp', 'desc')
            .onSnapshot((querySnapshot) => {
                var leads = []
                querySnapshot.forEach((doc) => {
                    leads.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setCompletedLeads(leads)
            })
    }

    function getSuccessLeads() {
        console.log('Getting Success Leads')
        DB.collection('leads')
            .where('confirmed', '==', true)
            .orderBy('timestamp', 'desc')
            .onSnapshot((querySnapshot) => {
                var leads = []
                querySnapshot.forEach((doc) => {
                    leads.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setSuccessLeads(leads)
            })
    }

    function getRejectedLeads() {
        console.log('Getting Rejected Leads')
        DB.collection('leads')
            .where('rejected', '==', true)
            .orderBy('timestamp', 'desc')
            .onSnapshot((querySnapshot) => {
                var leads = []
                querySnapshot.forEach((doc) => {
                    leads.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setRejectedLeads(leads)
            })
    }

    useEffect(() => {
        tabNo === 0 && !newLeads.length && getNewLeads()
        tabNo === 1 && !completedLeads.length && getCompletedLeads()
        tabNo === 2 && !successLeads.length && getSuccessLeads()
        tabNo === 3 && !rejectedLeads.length && getRejectedLeads()
    }, [tabNo])

    return (
        <AdminPanelTemplate>
            <Heading>Leads</Heading>

            <TabContainer>
                <Tabs
                    value={tabNo}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    aria-label="hello"
                >
                    {[
                        ['New Leads', newLeads],
                        ['Completed', completedLeads],
                        ['Success Leads', successLeads],
                        ['Rejected', rejectedLeads]
                    ].map((item, key) => (
                        <Tab
                            key={key}
                            label={`${item[0]} ${
                                tabNo === key ? `(${item[1].length})` : ''
                            }`}
                        />
                    ))}
                </Tabs>
            </TabContainer>

            <div>
                {[
                    [newLeads, ['complete', 'success', 'reject']],
                    [completedLeads, ['success', 'reject']],
                    [successLeads, []],
                    [rejectedLeads, []]
                ].map(
                    (item, key) =>
                        tabNo === key && (
                            <div key={key}>
                                {(!!item[0].length && (
                                    <LeadTable
                                        leadData={item[0]}
                                        tools={item[1]}
                                    />
                                )) || <NoDataFound>No Data Found</NoDataFound>}
                            </div>
                        )
                )}
            </div>
        </AdminPanelTemplate>
    )
}

const Heading = styled.h1`
    color: #333;
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
    margin-top: 1rem;
`

const TabContainer = styled.div`
    background-color: #fff;
    border: 1px solid #dddddd;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.07);
    border-radius: 15px;
    padding: 15px;
    margin: 30px auto;
    padding-bottom: 0;
    width: 100%;

    .MuiTabs-flexContainer {
        justify-content: space-between;
    }
`

const NoDataFound = styled.p`
    color: #333;
    text-align: center;
    margin-top: 50px;
    font-weight: 500;
`

export default Leads
