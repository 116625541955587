import { Container } from '@material-ui/core'
import { useState } from 'react'

export default function FaqSingle({ number, qn, ans }) {
    const [qnClicked, setQnClicked] = useState(false)
    return (
        <Container maxWidth="md">
            <div className="faq_single_item">
                <div
                    className={`number_faq faq_${(number % 6) + 1}`}
                    onClick={() => setQnClicked((prev) => !prev)}
                >
                    <p className="font-Quick font-bold">{number}</p>
                </div>
                <div className="faq_qn_ans">
                    <h4
                        className="font-Quick font-med"
                        onClick={() => setQnClicked((prev) => !prev)}
                    >
                        {qn}
                    </h4>
                    <div
                        className={
                            qnClicked ? 'answer_faq' : 'answer_faq hide_faq_ans'
                        }
                    >
                        <p className="font-Quick font-reg">{ans}</p>
                    </div>
                </div>
            </div>
        </Container>
    )
}
