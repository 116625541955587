import { Container, Grid, Hidden } from '@material-ui/core'
import robotGoodGrip from '../../Assets/layout_images/robotGoodGrip.png'
import robotNotes from '../../Assets/layout_images/robotNotes.png'
import robotAttention from '../../Assets/layout_images/robotAttention.png'
import robotPractical from '../../Assets/layout_images/robotPractical.png'
import robotKits from '../../Assets/layout_images/robotKits.png'
import { Link } from 'react-router-dom'

export default function RoboticsBenefit() {
    return (
        <div className="robotics_benefits">
            <div className="robot_benefit_header">
                <h2 className="font-Quick font-semi">Your benefits</h2>
                <p className="font-Quick font-med">
                    TeacherInd can make your life more fun through robotics
                </p>
            </div>
            <div className="robot_benefit_grid">
                <Container>
                    <Grid container alignItems="center">
                        <Grid item xs={6} md={8}>
                            <Grid container>
                                <Hidden smDown>
                                    <Grid md={4}></Grid>
                                </Hidden>
                                <Grid item xs={12} md={8}>
                                    <div className="content_benefit_robot before_line_robot">
                                        <h1 className="font-Quick font-semi">
                                            Good grip
                                        </h1>
                                        <p className="font-Quick font-med">
                                            Get a good grip on Robotics and
                                            learn awesome techniques to code
                                            your ideas.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <div className="content_benefit_robot_image">
                                <img src={robotGoodGrip} alt="robotGoodGrip" />
                            </div>
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <div className="content_benefit_robot_image ">
                                <img src={robotNotes} alt="robotNotes" />
                            </div>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <Grid container>
                                <Grid item xs={12} md={8}>
                                    <div className="content_benefit_robot after_line_robot">
                                        <h1 className="font-Quick font-semi">
                                            Hi-Tech Notes
                                        </h1>
                                        <p className="font-Quick font-med">
                                            Get helpful tips, tricks and
                                            techniques to gear up your robot.
                                        </p>
                                    </div>
                                </Grid>
                                <Hidden smDown>
                                    <Grid md={4}></Grid>
                                </Hidden>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} md={8}>
                            <Grid container>
                                <Hidden smDown>
                                    <Grid md={4}></Grid>
                                </Hidden>
                                <Grid item xs={12} md={8}>
                                    <div className="content_benefit_robot before_line_robot">
                                        <h1 className="font-Quick font-semi">
                                            Individual Attention
                                        </h1>
                                        <p className="font-Quick font-med">
                                            One Student. One Teacher.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <div className="content_benefit_robot_image">
                                <img
                                    src={robotAttention}
                                    alt="robotAttention"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <div className="content_benefit_robot_image">
                                <img
                                    src={robotPractical}
                                    alt="robotPractical"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <Grid container>
                                <Grid item xs={12} md={8}>
                                    <div className="content_benefit_robot after_line_robot">
                                        <h1 className="font-Quick font-semi">
                                            {`Hands-On approach`}
                                        </h1>
                                        <p className="font-Quick font-med">
                                            {`Your Enjoyment = Hands-On approach`}
                                        </p>
                                    </div>
                                </Grid>
                                <Hidden smDown>
                                    <Grid md={4}></Grid>
                                </Hidden>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} md={8}>
                            <Grid container>
                                <Hidden smDown>
                                    <Grid md={4}></Grid>
                                </Hidden>
                                <Grid item xs={12} md={8}>
                                    <div className="content_benefit_robot before_line_robot">
                                        <h1 className="font-Quick font-semi">
                                            Kits
                                        </h1>
                                        <p className="font-Quick font-med">
                                            Learning Kits available. Have fun
                                            with a robot
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <div className="content_benefit_robot_image">
                                <img src={robotKits} alt="robotKits" />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Hidden smDown>
                <div className="benefit_button_robot">
                    <Link
                        className="robot_book_button font-Quick font-bold"
                        to="/form"
                    >
                        Book A Free Demo Class
                    </Link>
                </div>
            </Hidden>
        </div>
    )
}
