import { Container, Grid } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { DB } from '../../firebase'
import BlogCard from '../partialComponents/BlogCard'
import BasicTemplate from '../templates/BasicTemplate'
import { createBlogUrl } from '../utils/createBlogUrl'

function Blogs() {
    const [blogs, setBlogs] = useState([])
    const [loading, setLoading] = useState(true)

    const last = useRef('')

    const history = useHistory()

    function blogHandler(id, title) {
        const url = createBlogUrl(id, title)
        history.push(`/blogs/read/${url}`)
    }

    function getMoreBlogs() {
        if (last.current === undefined) return

        DB.collection('blogs')
            .orderBy('timestamp', 'desc')
            .limit(4)
            .orderBy('title')
            .startAfter(last.current)
            .get()
            .then((querySnapshot) => {
                let blogArray = []

                last.current = querySnapshot.docs[querySnapshot.docs.length - 1]

                querySnapshot.forEach((doc) => {
                    blogArray.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })

                setBlogs((prev) => [...prev, ...blogArray])
            })
            .catch((error) => {
                console.log('Error getting blogs', error)
            })
    }

    function getBlogs() {
        setLoading(true)
        DB.collection('blogs')
            .orderBy('timestamp', 'desc')
            .orderBy('title')
            .limit(4)
            .get()
            .then((querySnapshot) => {
                let blogArray = []

                last.current = querySnapshot.docs[querySnapshot.docs.length - 1]

                querySnapshot.forEach((doc) => {
                    blogArray.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })

                setLoading(false)
                setBlogs(blogArray)
            })
            .catch((error) => {
                console.log('Error getting blogs', error)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getBlogs()
    }, [])

    return (
        <BasicTemplate>
            <BlogContainer>
                <Container>
                    <TitleContainer>
                        <Header>Teacherind Blogs</Header>
                        <Tagline>
                            {`Kerala's Leading One to One Tuition Platform`}
                        </Tagline>
                    </TitleContainer>
                    <div>
                        {!!blogs.length && <Featured>Featured Posts</Featured>}
                        <BlogListContainer>
                            <Grid
                                container
                                spacing={5}
                                direction="row"
                                alignItems="center"
                            >
                                {!!blogs.length &&
                                    !loading &&
                                    blogs.map((blog, key) => (
                                        <Grid key={key} item xs={12} sm={6}>
                                            <BlogCard
                                                title={blog?.title}
                                                blogHandler={blogHandler}
                                                id={blog?.id}
                                                description={blog?.description}
                                                titleImage={blog?.titleImage}
                                                duration={blog.duration}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>

                            {!blogs.length && !loading && (
                                <NoDataFound>No Blogs Found</NoDataFound>
                            )}

                            {!blogs.length && loading && (
                                <NoDataFound>Loading...</NoDataFound>
                            )}

                            {!!blogs.length && (
                                <ViewMore>
                                    <ViewMoreButton onClick={getMoreBlogs}>
                                        View More
                                    </ViewMoreButton>
                                </ViewMore>
                            )}
                        </BlogListContainer>
                    </div>
                </Container>
            </BlogContainer>
        </BasicTemplate>
    )
}

const BlogContainer = styled.div`
    background-color: #f2f3f7;
    min-height: 80vh;
    font-family: 'Quicksand', sans-serif;
    padding-bottom: 40px;
`

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    padding-top: 40px;
    border-bottom: 1px dashed #750092;
`
const Header = styled.h1`
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
`

const Tagline = styled.p`
    color: #555;
    text-align: center;
    max-width: 300px;
`

const Featured = styled.h1`
    color: #333;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    margin: 20px 0;
`

const NoDataFound = styled.p`
    color: #333;
    text-align: center;
    margin-top: 50px;
    font-weight: 500;
`

const BlogListContainer = styled.div`
    margin: 30px auto;
`

const ViewMore = styled.div`
    width: 100%;
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`
const ViewMoreButton = styled.button`
    color: #378ef7;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 15px;
    padding: 15px 20px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
`
export default Blogs
