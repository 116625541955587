import styled from 'styled-components'
import AdminPanelTemplate from '../templates/AdminPanelTemplate'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useState } from 'react'
import { Box, Button, Grid, TextField } from '@material-ui/core'
import CustomDialog from '../partialComponents/CustomDialog'
import { useHistory } from 'react-router-dom'
import { DB } from '../../firebase'
import firebase from 'firebase/app'

const myToolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }, { font: ['Sans Serif', 'monospace'] }],

    [{ size: [] }],
    [{ script: 'sub' }, { script: 'super' }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }],

    [{ align: [] }, { indent: '-1' }, { indent: '+1' }],

    ['clean'],
    ['link', 'image', 'video']
]

const modules = {
    toolbar: {
        container: myToolbar,
        handlers: {
            image: imageHandler
        }
    },
    clipboard: {
        // toggle to add extra line breaks when pasting HTML
        matchVisual: false
    }
}

function imageHandler() {
    var range = this.quill.getSelection()

    var value = prompt(`Please copy & paste the image url here.`)
    if (value) {
        this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER)
    }
}

function BlogEditor() {
    const [title, setTitle] = useState('')
    const [titleImage, setTitleImage] = useState('')
    const [description, setDescription] = useState('')
    const [duration, setDuration] = useState(5)
    const [blogContent, setBlogContent] = useState('')
    const [open, setOpen] = useState(false)

    const history = useHistory()

    const handleClickOpen = () => {
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }

    function handleCreateBlog() {
        DB.collection('blogs')
            .add({
                title: title,
                titleImage: titleImage,
                description: description,
                duration: `${duration} min read`,
                content: blogContent,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(() => {
                handleClose()
                history.push('/admin/blogs/view')
            })
            .catch(() => {
                console.log('Error Occured')
            })
    }

    return (
        <AdminPanelTemplate>
            <Heading>Create New Blog</Heading>

            <BlogEditorContainer>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            label="Title"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            value={titleImage}
                            onChange={(e) => setTitleImage(e.target.value)}
                            label="Title Image Url"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            label="Short Description"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="flex-end">
                            <TextField
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                label="Duration"
                                type="number"
                                style={{ marginRight: '5px' }}
                            />
                            {` min read`}
                        </Box>
                    </Grid>
                </Grid>
                <EditorContainer>
                    <ReactQuill
                        value={blogContent}
                        onChange={(value) => setBlogContent(value)}
                        placeholder={`Write something...`}
                        modules={modules}
                        theme="snow"
                    />
                </EditorContainer>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    width="100%"
                >
                    <Button
                        onClick={handleClickOpen}
                        variant="contained"
                        disabled={
                            !title ||
                            !description ||
                            !titleImage ||
                            !blogContent ||
                            !duration
                        }
                        color="primary"
                        size="large"
                        style={{ marginTop: '30px', marginBottom: '30px' }}
                    >
                        CREATE
                    </Button>
                </Box>
            </BlogEditorContainer>

            <CustomDialog
                title={'Are you Sure ?'}
                description={`A new blog will be created...`}
                successText={'OK'}
                cancelText={'Cancel'}
                successFunc={handleCreateBlog}
                open={open}
                handleClose={handleClose}
            />
        </AdminPanelTemplate>
    )
}

const Heading = styled.h1`
    color: #333;
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
    margin-top: 1rem;
`

const EditorContainer = styled.div`
    margin-top: 50px;

    .quill {
        border: 1px solid #ccc;
        background-color: #fff;
    }

    .ql-editor {
        max-height: 550px;
        min-height: 375px;
        font-size: 16px;
        font-family: 'Quicksand', sans-serif;
    }
    .ql-container,
    .ql-toolbar,
    .ql-snow {
        border: none;
    }
    .ql-container {
        max-width: 650px;
        margin: auto;
    }
    .ql-toolbar {
        border-bottom: 1px solid #ccc;
    }
    .ql-editor .ql-video {
        width: 100%;
        height: 100%;
        min-height: 350px;
    }
`

const BlogEditorContainer = styled.div`
    background-color: #ffffff96;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
    width: 100%;
    padding: 30px;
`

export default BlogEditor
