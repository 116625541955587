import { Container } from '@material-ui/core'
import ContactInForm from '../partialComponents/ContactInForm'
import BasicTemplate from '../templates/BasicTemplate'

export default function ContactUs() {
    return (
        <BasicTemplate>
            <div className="contact_us_page">
                <Container>
                    <ContactInForm form={false} />
                </Container>
            </div>
        </BasicTemplate>
    )
}
