export default function IdealForStudentsFooter() {
    return (
        <div className="teacherind_ideal_for_student_footer">
            <h4 className="font-Quick font-med">
                Why is teacherInd Ideal for students?
            </h4>
            <p className="font-Quick font-reg">
                We realised that every student required a unique method of
                teaching style. Some needed daily assignments, some needed daily
                classes and some needed less homework but more intellectual
                support. So, every student is analysed for IQ and EQ before
                choosing a teaching approach.
                <br />
                <br />
                TeacherInd distinguishes itself from all other Edtech firms by
                providing a unique human experience at an affordable tuition
                fee. All students will receive a happy and satisfied tuition
                from TeacherInd.
            </p>
        </div>
    )
}
