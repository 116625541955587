import { useState, useEffect, createContext, useContext } from 'react'
import app from '../../firebase'

const authContext = createContext()

export const useAuthContext = () => useContext(authContext)

export function AuthContextProvider({ children }) {
    const [user, setUser] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const onAuthStateChange = () => {
        setLoading(true)
        app.auth().onAuthStateChanged(function (user) {
            if (user) {
                setUser(user)
                setLoading(false)
            } else {
                setUser('')
                setLoading(false)
            }
        })
    }

    const login = async (email, password) => {
        setError('')
        await app
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                console.log('logged in')
            })
            .catch((error) => {
                setError('! Error Occured')
                console.log('Error Occured')
            })
    }

    const logout = async () => {
        await app
            .auth()
            .signOut()
            .catch((error) => {
                console.log('Error Occured', error)
            })
    }

    useEffect(() => {
        onAuthStateChange()
    }, [])

    const values = {
        user,
        login,
        logout,
        error,
        loading
    }

    return (
        <authContext.Provider value={values}>
            {!loading && children}
        </authContext.Provider>
    )
}
