import { Container, Hidden } from '@material-ui/core'
import CustomContainer from './CustomContainer'
import robotLines from '../../Assets/layout_images/robot_lines.png'
import robot from '../../Assets/layout_images/robot.png'
import robotWave1 from '../../Assets/layout_images/robotWave1.png'
import { Link } from 'react-router-dom'

export default function RoboticsHero() {
    return (
        <div className="robotics_hero">
            <div className="hero_robotics_images">
                <img
                    src={robotLines}
                    alt="robotLines"
                    className="robot_lines"
                />
                <img
                    src={robotWave1}
                    alt="robotWave1"
                    className="robot_wave_1"
                />
            </div>
            <Container>
                <CustomContainer>
                    <div className="robot_hero_wrapper">
                        <img src={robot} alt="robot" className="robot" />

                        <div className="robot_hero_content">
                            <h1 className="font-Rale font-xbold">
                                Learn all about <span>Robotics</span>
                            </h1>
                            <p className="font-Quick font-semi">
                                And create a stunning TI-BOT.
                            </p>
                            <Hidden smDown>
                                <div className="robot_button">
                                    <Link
                                        to="/form"
                                        className="robot_book_button_light font-Quick font-bold"
                                    >
                                        Book A Free Class
                                    </Link>
                                </div>
                            </Hidden>
                        </div>
                    </div>
                </CustomContainer>
            </Container>
        </div>
    )
}
