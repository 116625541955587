import { useEffect, useState } from 'react'
import LoadingAnimation from '../LoadingAnimation'
import BasicTemplate from '../templates/BasicTemplate'

export default function TutorGoogleForm() {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <BasicTemplate>
            {loading && <LoadingAnimation />}
            <div className="tutor_google_form">
                <iframe
                    title="Become a tutor at teacherind"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeHDWmirpFizu6wDjX7yNX4TOI3S1zZFVT4tymrk6URrkKzIQ/viewform?embedded=true"
                    width="640"
                    height="1400"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    onLoad={() => setLoading(false)}
                >
                    Loading…
                </iframe>
            </div>
        </BasicTemplate>
    )
}
