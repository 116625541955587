import { Hidden } from '@material-ui/core'
import { Link } from 'react-router-dom'
import btechBenefitImage from '../../Assets/layout_images/btechBenefitImage.png'

export default function BtechBenefits() {
    const benefits = [
        {
            title: 'Module Wise',
            content:
                'Get tutored for your difficult modules only. Pay only for what you learn.'
        },
        {
            title: 'Crash Courses',
            content: 'For those Last Minute lovers.'
        },
        {
            title: 'Regular Tests',
            content: 'Regular Tests and assignments.'
        },
        {
            title: 'Exam Oriented',
            content: `Skyrocket your CGPA with our syllabus oriented & chapter wise tutoring`
        },
        {
            title: 'Conquer your semester',
            content: `Get support for all your difficult subjects`
        },
        {
            title: 'Expert Tutors',
            content: `Improve your problem-solving skills through simple shortcut techniques`
        }
    ]
    return (
        <div className="btech_benefits_section">
            <div>
                <h2 className="font-Quick font-semi">Your benefits</h2>
                <p className="font-Quick font-med">
                    TeacherInd can make your B.Tech Life easier
                </p>

                <div className="benefit_circle_arrangement">
                    <div className="image_benefit_circle">
                        <div className="benefit_btech_image">
                            <img src={btechBenefitImage} alt="benefits" />
                        </div>
                        <div className="btech_benefit_bottom_content">
                            <h4 className="font-Quick font-bold">
                                Individual Attention
                            </h4>
                            <p className="font-Quick font-med">
                                {`One Student. One Teacher.`}
                            </p>
                        </div>
                        <div className="benefit_cards">
                            {benefits.map((el, key) => (
                                <div
                                    className={`btech_benefit_card_${key + 1}`}
                                    key={key}
                                >
                                    <h4 className="font-Quick font-bold">
                                        {el.title}
                                    </h4>
                                    <p className="font-Quick font-med">
                                        {el.content}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Hidden smDown>
                    <div className="benefit_button_btech">
                        <Link
                            className="btech_book_button font-Quick font-bold"
                            to="/form"
                        >
                            Book A Free Demo Class
                        </Link>
                    </div>
                </Hidden>
            </div>
        </div>
    )
}
