import { Container, Grid } from '@material-ui/core'
import React from 'react'
import StateBenefitCard from './StateBenefitCard'

export default function StateBenefits({ benefits }) {
    return (
        <div className="state_benefits">
            <h2 className="font-Quick font-semi">Your benefits</h2>
            <p className="font-Quick font-med">
                Teacherind can make your life easier
            </p>
            <div className="benefit_cards_state">
                <Container>
                    <Grid container spacing={3} justify="center">
                        {benefits.map((el, key) => (
                            <StateBenefitCard
                                image={el.image}
                                title={el.title}
                                content={el.content}
                                key={key}
                            />
                        ))}
                    </Grid>
                </Container>
            </div>
        </div>
    )
}
