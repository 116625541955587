import HelmetGenerator from '../partialComponents/HelmetGenerator'
import SpokenHero from '../partialComponents/SpokenHero'
import BasicTemplate from '../templates/BasicTemplate'
import { helmets } from '../utils/helmets'
import WhoNeedSpoken from '../partialComponents/WhoNeedSpoken'
import ImproveEnglish from '../partialComponents/ImproveEnglish'
import FaqContainer from '../partialComponents/FaqContainer'
import { faqs } from '../utils/faqs'
import { useEffect } from 'react'
import GetStarted from '../partialComponents/GetStarted'

export default function SpokenEnglish() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <HelmetGenerator details={helmets.spokenEnglish} />
            <BasicTemplate>
                <div className="spoken_page_section">
                    <SpokenHero />
                    <WhoNeedSpoken />
                    <ImproveEnglish />
                    <GetStarted type={'spoken_english'} color={'#444'} />

                    <FaqContainer faqList={faqs.spokenEnglish} />
                </div>
            </BasicTemplate>
        </>
    )
}
