import attentionSchool from '../../Assets/layout_images/attentionSchool.svg'
import crashSchool from '../../Assets/layout_images/crashSchool.svg'
import winnerSchool from '../../Assets/layout_images/winnerSchool.svg'
import wonSchool from '../../Assets/layout_images/wonSchool.svg'
import notesSchool from '../../Assets/layout_images/notesSchool.svg'
import mediumSchool from '../../Assets/layout_images/mediumSchool.svg'
import examsSchool from '../../Assets/layout_images/examsSchool.svg'
import textbook from '../../Assets/layout_images/textbook.svg'

export const benefits = {
    state: [
        {
            image: attentionSchool,
            title: 'Individual Attention',
            content: 'One Student. One Teacher.'
        },
        {
            image: crashSchool,
            title: 'Crash Courses',
            content: 'For those who require urgent help during exams'
        },
        {
            image: textbook,
            title: 'Good grip on your Textbook',
            content:
                'Get a good grip on SCERT Textbooks Get tutored only for your most difficult chapters'
        },
        {
            image: examsSchool,
            title: 'Tests',
            content: 'Regular Tests and assignments'
        },
        {
            image: notesSchool,
            title: 'Get important notes ',
            content:
                'Get helpful tips, tricks, and learning techniques for Kerala State Board Text Books'
        },
        {
            image: mediumSchool,
            title: 'All Medium',
            content:
                'Get help for both English Medium and Malayalam Medium classes.'
        },
        {
            image: wonSchool,
            title: 'Conquer every exam',
            content: 'Get support for all your difficult subjects'
        }
    ],
    cbse: [
        {
            image: attentionSchool,
            title: 'Individual Attention',
            content: 'One Student. One Teacher.'
        },
        {
            image: crashSchool,
            title: 'Crash Courses',
            content: 'For those who require urgent help during exams'
        },
        {
            image: textbook,
            title: 'Good grip on your Textbook',
            content:
                'Become an expert in your textbooks and gain practical examples also'
        },
        {
            image: examsSchool,
            title: 'Tests',
            content: 'Regular Tests and assignments'
        },
        {
            image: notesSchool,
            title: 'Get important notes',
            content:
                'Get helpful tips, tricks, and learning techniques for NCERT and Prescribed textbooks for ICSE & IGCSE'
        },
        {
            image: winnerSchool,
            title: 'Get your competitive Edge',
            content: 'Score good marks by strengthening your fundamentals'
        },
        {
            image: wonSchool,
            title: 'Conquer every exam',
            content: 'Get support for all your difficult subjects'
        }
    ]
}
