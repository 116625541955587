import { Grid, Hidden } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { linksInFooter } from '../utils/linksInFooter'

export default function QuickLinksFooter() {
    function generateLinks(linkContainer) {
        return (
            <div className="footer_quick_link_section">
                <h4 className="title font-Quick font-med">
                    {linkContainer.title}
                </h4>
                <div>
                    {linkContainer.links.map((item, key) => (
                        <Link
                            className="font-Quick font-reg"
                            to={item[1]}
                            key={key}
                        >
                            {item[0]}
                        </Link>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div className="quick_links_section">
            <Grid container direction="row" justify="center">
                <Grid item xs={6} md={2}>
                    {generateLinks(linksInFooter.courses)}
                    {generateLinks(linksInFooter.syllabus)}
                </Grid>
                <Hidden smDown>
                    <Grid item md={4}>
                        <Grid container>
                            <Grid item md={6}>
                                {generateLinks(linksInFooter.state)}
                            </Grid>
                            <Grid item md={6}>
                                {generateLinks(linksInFooter.cbse)}
                            </Grid>
                            <Grid item md={6}>
                                {generateLinks(linksInFooter.icse)}
                            </Grid>
                            <Grid item md={6}>
                                {generateLinks(linksInFooter.igsce)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={6} md={3}>
                    {generateLinks(linksInFooter.btech)}
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container>
                        <Grid item xs={6} md={12}>
                            {generateLinks(linksInFooter.skillDevelopment)}
                        </Grid>
                        <Grid item xs={6} md={12}>
                            {generateLinks(linksInFooter.quickLinks)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
