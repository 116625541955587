import BasicTemplate from '../templates/BasicTemplate'
import HeroHome from '../partialComponents/HeroHome'
import WhyNeedUsHome from '../partialComponents/WhyNeedUsHome'
import BenefitsHome from '../partialComponents/BenefitsHome'
import UniqueHome from '../partialComponents/UniqueHome'
import StudentTestimonials from '../partialComponents/StudentTestimonials'
import { useEffect } from 'react'
import HelmetGenerator from '../partialComponents/HelmetGenerator'
import { helmets } from '../utils/helmets'
import Counter from '../partialComponents/Counter'

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <HelmetGenerator details={helmets.home} />
            <BasicTemplate>
                <div className="home_section">
                    <HeroHome />
                    <WhyNeedUsHome />
                    <BenefitsHome />
                    <UniqueHome />
                    <Counter />
                    <StudentTestimonials />
                </div>
            </BasicTemplate>
        </>
    )
}
