import { Box } from '@material-ui/core'
import { useLocation } from 'react-router'
import Footer from '../partialComponents/Footer'
import Header from '../partialComponents/Header'

export default function BasicTemplate({ children }) {
    const location = useLocation()
    return (
        <div>
            {location.pathname !== '/form' && <Header />}
            <Box marginTop={location.pathname !== '/form' ? '64px' : '0'}>
                {children}
            </Box>
            {location.pathname !== '/form' && <Footer />}
        </div>
    )
}
