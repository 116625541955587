import styled from 'styled-components'
import { symbols } from '../utils/symbols'

function BlogCard({
    id,
    title,
    titleImage,
    description,
    duration,
    blogHandler
}) {
    return (
        <BlogCardContainer>
            <TitleImage>
                <img src={titleImage} alt={title} />
            </TitleImage>
            <Content>
                <Title>{title}</Title>
                <Description>{description}</Description>

                <Action>
                    <ReadLink
                        onClick={() => blogHandler(id, title)}
                    >{`Read full article`}</ReadLink>
                    <ReadTime>{`${duration} ${symbols.STAR}`}</ReadTime>
                </Action>
            </Content>
        </BlogCardContainer>
    )
}

const BlogCardContainer = styled.div`
    width: 100%;
    max-width: 400px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1),
        3px 3px 16px rgba(190, 205, 226, 0.5), -6px -6px 16px #ffffff,
        inset 0px 8px 3px rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 960px) {
        max-height: 250px;
        flex-direction: row;
        justify-content: flex-start;
    }
    @media (min-width: 600px) {
        max-width: 600px;
    }
`

const TitleImage = styled.div`
    height: 200px;
    border-radius: 10px 10px 0 0;
    width: 100%;
    position: relative;

    @media (min-width: 960px) {
        border-radius: 10px 0 0 10px;
        max-width: 250px;
    }

    img {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 10px 10px 0 0;

        @media (min-width: 960px) {
            border-radius: 10px 0 0 10px;
        }
    }
`

const Content = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 15px;
    width: 100%;

    min-height: 180px;
    @media (min-width: 600px) {
        min-height: 200px;
        flex-grow: 1;
    }
`

const Title = styled.h1`
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3rem;
    font-weight: 600;
    color: #2e3a59;
    margin-bottom: 15px;
    padding-top: 10px;
`
const Description = styled.p`
    color: #546183;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 15px;
    flex-grow: 1;
`

const Action = styled.div`
    justify-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
`
const ReadLink = styled.p`
    color: #378ef7;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
`
const ReadTime = styled.p`
    color: #8d949e;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    font-size: 14px;
    align-items: right;
`

export default BlogCard
