import { Redirect } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'
import logo from '../../Assets/logos/logo.svg'
import { useState } from 'react'
import styled from 'styled-components'

function AdminLogin() {
    const { user, login, error, loading } = useAuthContext()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    return (
        (!user && (
            <LoginPageContainer>
                <LogoContainer>
                    <img src={logo} alt="logo" />
                    <p>Kerala’s Leading One to One Tuition Platform</p>
                </LogoContainer>

                <WhiteCard>
                    <Heading>Welcome Admin</Heading>
                    {!!error && <ErrorMsg>{error}</ErrorMsg>}

                    <InputField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email ID"
                    />

                    <InputField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                    />

                    <LoginButton
                        disabled={loading}
                        onClick={() => login(email, password)}
                    >
                        Login
                    </LoginButton>
                </WhiteCard>
            </LoginPageContainer>
        )) || <Redirect to="/admin" />
    )
}

const LoginPageContainer = styled.div`
    min-height: 100vh;
    padding: 2.5rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f2f4fc;
    font-family: 'Quicksand', sans-serif;
`

const WhiteCard = styled.div`
    background-color: #fff;
    border: 1px solid #dddddd;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.07);
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 30px;
    max-width: 450px;
    width: 100%;
`

const LogoContainer = styled(WhiteCard)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > img {
        width: 90%;
        max-width: 200px;
    }

    > p {
        margin-top: 20px;
        max-width: 300px;
        color: #555;
        text-align: center;
        font-weight: 500;
    }
`

const Heading = styled.h1`
    color: #252b42;
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 700;
`
const ErrorMsg = styled.p`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-weight: 500;
`
const InputField = styled.input`
    width: 100%;
    outline: none;
    border: none;
    background: #f1f3f8;
    border-radius: 10px;
    color: #666;
    padding: 15px 20px;
    font-size: 16px;
    margin: 10px 0;
    font-weight: 500;

    &::placeholder {
        color: #9ea8bd;
    }
`
const LoginButton = styled.button`
    background-color: #0874f5;
    outline: none;
    border: none;
    color: #fff;
    width: 100%;
    padding: 15px 20px;
    border-radius: 10px;
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
    transition: transform ease-in-out 0.15s;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-weight: 600;

    &:hover {
        transform: scale(1.02);
    }
    &:active {
        transform: scale(0.98);
    }
`

export default AdminLogin
