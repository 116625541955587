import { Container, Grid } from '@material-ui/core'
import { useCallback, useRef, useState } from 'react'
import { counterList } from '../utils/counterList'
import AnimatedCounter from './AnimatedCounter'

function CallBackIntegrator({ workedOnceStatus, callBackRef, children }) {
    return !workedOnceStatus ? (
        <div ref={callBackRef}>{children}</div>
    ) : (
        <div>{children}</div>
    )
}

export default function Counter() {
    const [workedOnce, setWorkedOnce] = useState(false)

    const observer = useRef()
    const timeFactor = 120

    const counterActive = useCallback((node) => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setWorkedOnce(true)
                }
            },
            { threshold: 0.5 }
        )
        if (node) observer.current.observe(node)
    }, [])

    return (
        <CallBackIntegrator
            workedOnceStatus={workedOnce}
            callBackRef={counterActive}
        >
            <div className="counter_home">
                <Container>
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                    >
                        {counterList.map((el, key) => (
                            <Grid item xs={6} md={3} key={key}>
                                <div className="counter_wrapper">
                                    <div className="counter_image">
                                        <img src={el.img} alt={el.text} />
                                    </div>
                                    <h3 className="font-Quick font-bold counter_number">
                                        <AnimatedCounter
                                            start={workedOnce}
                                            speed={Math.round(
                                                el.count / timeFactor
                                            )}
                                            count={el.count}
                                        />
                                    </h3>
                                    <p className="font-Quick font-med counter_description">
                                        {el.text}
                                    </p>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
        </CallBackIntegrator>
    )
}
