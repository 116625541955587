import { useEffect, useState, useRef } from 'react'

export default function TypingEffect({ words, colors }) {
    const index = useRef(0)
    const wordIndex = useRef(0)
    const [currentText, setCurrentText] = useState('')
    const [deleting, setDeleting] = useState(false)

    useEffect(() => {
        index.current = 0
        wordIndex.current = 0
        setCurrentText('')
        setDeleting(false)
    }, [words])

    useEffect(() => {
        const timeoutId = setTimeout(
            () => {
                if (!deleting) {
                    if (index.current === words[wordIndex.current].length) {
                        setTimeout(() => {
                            setDeleting(true)
                        }, 1500)
                    } else {
                        setCurrentText(
                            words[wordIndex.current].substring(
                                0,
                                index.current + 1
                            )
                        )
                    }

                    index.current += 1
                } else {
                    index.current -= 1
                    if (index.current === 0) {
                        setTimeout(() => {
                            wordIndex.current =
                                (wordIndex.current + 1) % words.length
                            setDeleting(false)
                        }, 500)
                    } else {
                        setCurrentText(
                            words[wordIndex.current].substring(
                                0,
                                index.current - 1
                            )
                        )
                    }
                }
            },
            deleting ? 80 : 200
        )
        return () => {
            clearTimeout(timeoutId)
        }
    }, [currentText, deleting, words])

    return (
        <>
            <span
                className="typing_text"
                style={{
                    color: colors[wordIndex.current]
                }}
            >
                {currentText}
            </span>
            <span
                style={{
                    color: colors[wordIndex.current]
                }}
                className="cursor_typing"
            >{`|`}</span>
        </>
    )
}
