export const tsToDate = (timestamp) => {
    let date = timestamp.toDate()

    let mm = date.getMonth() + 1
    mm = mm < 10 ? `0${mm}` : mm
    let dd = date.getDate()
    dd = dd < 10 ? `0${dd}` : dd
    let yyyy = date.getFullYear()

    let hr = date.getHours()
    let min = date.getMinutes()
    let sec = date.getSeconds()

    // date = mm + '/' + dd + '/' + yyyy;
    return [
        `${dd}/${mm}/${yyyy}`,
        `${hr < 10 ? `0${hr}` : hr}:${min < 10 ? `0${min}` : min}:${
            sec < 10 ? `0${sec}` : sec
        }`
    ]
}
