import FormSubProgramFIeld from './FormSubProgramFIeld'

export default function FormLeadDetails({
    formValues,
    handleChange,
    handleNext,
    handleBookClass,
    requestingClass,
    page1
}) {
    if (page1) {
        return (
            <>
                <div className="input_field">
                    <input
                        className="font-Quick font-med"
                        name="name"
                        value={formValues.name}
                        onChange={handleChange}
                        type="text"
                        placeholder="Full Name"
                    />
                </div>
                <div className="input_field">
                    <input
                        className="font-Quick font-med"
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                        type="email"
                        placeholder="Email ID"
                    />
                </div>
                <div className="phone_number">
                    <div className="input_field country_code">
                        <input
                            className="font-Quick font-med"
                            value="🇮🇳 +91"
                            type="text"
                            disabled
                        />
                    </div>
                    <div className="input_field">
                        <input
                            className="font-Quick font-med"
                            name="phoneNumber"
                            value={formValues.phoneNumber}
                            onChange={handleChange}
                            type="number"
                            placeholder="Mobile Number"
                        />
                    </div>
                </div>

                <div
                    className="font-Quick font-bold button_form"
                    onClick={handleNext}
                >
                    Next
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="input_field">
                    <select
                        className="font-Quick font-med"
                        name="program"
                        value={formValues.program}
                        onChange={handleChange}
                    >
                        <option value="">Select Program</option>
                        {[`School`, `B.tech`, `Robotics`, `Spoken English`].map(
                            (item, key) => (
                                <option value={item} key={key}>
                                    {item}
                                </option>
                            )
                        )}
                    </select>
                </div>
                <FormSubProgramFIeld
                    program={formValues.program}
                    subProgram={formValues.subProgram}
                    handleChange={handleChange}
                />

                <div
                    className="font-Quick font-bold button_form"
                    onClick={!requestingClass ? handleBookClass : () => {}}
                >
                    Book A Free Class
                </div>
            </>
        )
    }
}
