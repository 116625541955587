import coursesCounter from '../../Assets/layout_icons/coursesCounter.svg'
import liveClassCounter from '../../Assets/layout_icons/liveClassCounter.svg'
import studentCounter from '../../Assets/layout_icons/studentCounter.svg'
import tutorCounter from '../../Assets/layout_icons/tutorCounter.svg'

export const counterList = [
    {
        text: 'Satisfied Students',
        count: 1200,
        img: studentCounter
    },
    {
        text: 'Live Classes',
        count: 50000,
        img: liveClassCounter
    },
    {
        text: 'Tutors',
        count: 250,
        img: tutorCounter
    },
    {
        text: 'Courses',
        count: 300,
        img: coursesCounter
    }
]
