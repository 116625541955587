import FaqSingle from './FaqSingle'

export default function FaqContainer({ faqList }) {
    return (
        <div className="all_faqs_section">
            <h2 className="font-Quick font-semi">Frequently Asked Questions</h2>
            <div>
                {faqList.map((item, key) => (
                    <FaqSingle
                        key={key}
                        number={key + 1}
                        qn={item.qn}
                        ans={item.ans}
                    />
                ))}
            </div>
        </div>
    )
}
