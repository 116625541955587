import BasicTemplate from '../templates/BasicTemplate'
import btechCloud from '../../Assets/layout_images/btechCloud.png'
import btechWave1 from '../../Assets/layout_images/btechWave1.png'
import { faqs } from '../utils/faqs'
import FaqContainer from '../partialComponents/FaqContainer'
import { whoCanBenefit } from '../utils/whoCanBenefit'
import HelmetGenerator from '../partialComponents/HelmetGenerator'
import { helmets } from '../utils/helmets'
import { useEffect } from 'react'
import BtechHero from '../partialComponents/BtechHero'
import BtechBenefits from '../partialComponents/BtechBenefits'
import GetStarted from '../partialComponents/GetStarted'
import WhoCanBenefitHeptagon from '../partialComponents/WhoCanBenefitHeptagon'
import { useLocation } from 'react-router'

export default function Btech() {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])
    return (
        <>
            <HelmetGenerator details={helmets.btech} />

            <BasicTemplate>
                <div className="btech_section">
                    <BtechHero />

                    <BtechBenefits />

                    <div className="btech_cloud_section">
                        <img src={btechCloud} alt="cloud 1" />
                    </div>

                    <WhoCanBenefitHeptagon
                        page={'btech'}
                        waveTop={btechWave1}
                        whoCanBenefits={whoCanBenefit.btech}
                    />

                    <GetStarted type={'btech'} color={'#ffd5f9'} />

                    <FaqContainer faqList={faqs.btech} />
                </div>
            </BasicTemplate>
        </>
    )
}
