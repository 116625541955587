import BasicTemplate from '../templates/BasicTemplate'
import FaqContainer from '../partialComponents/FaqContainer'
import { faqs } from '../utils/faqs'
import robotRocket from '../../Assets/layout_images/robotRocket.svg'
import roboticsWave2 from '../../Assets/layout_images/roboticsWave2.png'
import RoboticsHero from '../partialComponents/RoboticsHero'
import RoboticsBenefit from '../partialComponents/RoboticsBenefit'
import { whoCanBenefit } from '../utils/whoCanBenefit'
import HelmetGenerator from '../partialComponents/HelmetGenerator'
import { helmets } from '../utils/helmets'
import { useEffect } from 'react'
import GetStarted from '../partialComponents/GetStarted'
import WhoCanBenefitHeptagon from '../partialComponents/WhoCanBenefitHeptagon'

export default function Robotics() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <HelmetGenerator details={helmets.robotics} />
            <BasicTemplate>
                <div className="robotics_page">
                    <RoboticsHero />
                    <RoboticsBenefit />
                    <div className="robotics_rocket">
                        <img src={robotRocket} alt="robotRocket" />
                    </div>
                    <WhoCanBenefitHeptagon
                        page={'robotics'}
                        waveTop={roboticsWave2}
                        whoCanBenefits={whoCanBenefit.robotics}
                    />

                    <GetStarted type={'robotics'} color={'#218091'} />

                    <FaqContainer faqList={faqs.robotics} />
                </div>
            </BasicTemplate>
        </>
    )
}
