import { Container, Grid } from '@material-ui/core'

export default function ImproveEnglish() {
    const improvePoints = [
        {
            title: 'Individual Attention',
            description: 'One Student. One Teacher.'
        },
        {
            title: 'Practical Learning',
            description: 'Become a fluent speaker after the course'
        },
        {
            title: 'Get a good grip on your English',
            description: 'Learn all about the fundamentals of English'
        },
        {
            title: 'Experienced Tutors',
            description:
                'Our tutors have excellent communication in english as well as your native language so that you can learn seamlessly.'
        },
        {
            title: 'Learn On-Demand',
            description:
                'If you need more individual attention , you can extend the classes till you have mastered english'
        }
    ]
    return (
        <div className="improve_english_section">
            <Container maxWidth="md">
                <h2 className="title_improve_english font-Quick font-semi">
                    How can your English improve with teacherInd?
                </h2>
                <Grid container spacing={1} justify="center">
                    {improvePoints.map((el, key) => (
                        <Grid item xs={6} sm={4} key={key}>
                            <div className="improve_english_card">
                                <h4 className="font-Quick font-bold">
                                    {el.title}
                                </h4>
                                <p className="font-Quick font-med">
                                    {el.description}
                                </p>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    )
}
