import BasicTemplate from '../templates/BasicTemplate'
import { useState } from 'react'
import { Container } from '@material-ui/core'
import ContactInForm from '../partialComponents/ContactInForm'

import {
    validateEmail,
    validatePhoneNumber,
    validateTexts
} from '../utils/validations'
import { DB, getTimeStamp } from '../../firebase'
import ErrorStatus from '../partialComponents/ErrorStatus'
import FormLeadDetails from '../partialComponents/FormLeadDetails'
import { Redirect } from 'react-router-dom'

export default function FormPage() {
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        program: '',
        subProgram: ''
    })
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [loading, setLoading] = useState('')
    const [requestingClass, setrequestingClass] = useState(false)
    const [stDetailStatus, setStDetailStatus] = useState(false)
    // GOTO next page

    const [bookedClass, setbookedClass] = useState(false)
    // goto success page

    const msgTypes = {
        error: 'error',
        success: 'success',
        waiting: 'waiting'
    }

    function generateMsg(type, msg) {
        setError(type === msgTypes.error ? msg : '')
        setLoading(type === msgTypes.waiting ? msg : '')
        setSuccess(type === msgTypes.success ? msg : '')
    }

    function handleChange(e) {
        setFormValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    function handleNext() {
        generateMsg(msgTypes.error, '')

        if (!validateTexts(formValues.name)) {
            generateMsg(msgTypes.error, 'Please Enter Name')
            return
        } else {
            if (!validateEmail(formValues.email)) {
                generateMsg(msgTypes.error, 'Invalid Email')
                return
            } else {
                if (!validatePhoneNumber(formValues.phoneNumber)) {
                    generateMsg(
                        msgTypes.error,
                        'Enter a 10 digit valid phone no.'
                    )
                    return
                } else {
                    setStDetailStatus(true)
                }
            }
        }
    }

    function handleBookClass() {
        generateMsg(msgTypes.error, '')
        setrequestingClass(true)

        if (!validateTexts(formValues.name)) {
            generateMsg(msgTypes.error, 'Please Enter Name')
            setStDetailStatus(false)
            return
        } else {
            if (!validateEmail(formValues.email)) {
                generateMsg(msgTypes.error, 'Invalid Email')
                setStDetailStatus(false)
                return
            } else {
                if (!validatePhoneNumber(formValues.phoneNumber)) {
                    generateMsg(
                        msgTypes.error,
                        'Enter a 10 digit valid phone no.'
                    )
                    setStDetailStatus(false)
                    return
                } else {
                    if (!validateTexts(formValues.program)) {
                        generateMsg(msgTypes.error, 'please select a program')
                        return
                    } else {
                        if (!validateTexts(formValues.subProgram)) {
                            generateMsg(
                                msgTypes.error,
                                'please complete the 2nd field'
                            )
                            return
                        } else {
                            generateMsg(msgTypes.waiting, 'Requesting...')
                            DB.collection('leads')
                                .add({
                                    name: formValues.name,
                                    email: formValues.email,
                                    phoneNumber: formValues.phoneNumber,
                                    program: formValues.program,
                                    subProgram: formValues.subProgram,
                                    timestamp: getTimeStamp(),
                                    rejected: false,
                                    completed: false,
                                    confirmed: false
                                })
                                .then(() => {
                                    generateMsg(
                                        msgTypes.success,
                                        'Booked SuccessFully'
                                    )
                                    setbookedClass(true)
                                    setrequestingClass(false)
                                })
                                .catch(() => {
                                    generateMsg(msgTypes.error, 'Error Occured')
                                    setrequestingClass(false)
                                })
                        }
                    }
                }
            }
        }
    }

    return (
        <BasicTemplate>
            <div className="form_page_section">
                <Container>
                    <div className="form_page_wrapper">
                        <ContactInForm form={true} />
                        <div className="form_card">
                            {(!bookedClass && (
                                <>
                                    <h1 className="font-Quick font-bold">
                                        {stDetailStatus
                                            ? 'Select Details'
                                            : 'Enter Details'}
                                    </h1>

                                    <ErrorStatus
                                        error={error}
                                        success={success}
                                        loading={loading}
                                    />
                                    <FormLeadDetails
                                        handleChange={handleChange}
                                        handleNext={handleNext}
                                        formValues={formValues}
                                        handleBookClass={handleBookClass}
                                        requestingClass={requestingClass}
                                        page1={!stDetailStatus}
                                    />
                                </>
                            )) || <Redirect to="/thankyou" />}
                        </div>
                    </div>
                </Container>
            </div>
        </BasicTemplate>
    )
}
