import Slider from 'react-slick'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { Box } from '@material-ui/core'
import { testimonials } from '../utils/testimonials'

function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={`${className} arrow_slick`}
            style={style}
            onClick={onClick}
        >
            <ChevronRightIcon style={{ color: '#222', fontSize: '20px' }} />
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={`${className} arrow_slick`}
            style={style}
            onClick={onClick}
        >
            <ChevronLeftIcon style={{ color: '#222', fontSize: '20px' }} />
        </div>
    )
}

export default function StudentTestimonials() {
    var settings = {
        dots: true,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 3,
        swipeToSlide: true,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        cssEase: 'ease-in-out',

        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }
    return (
        <div className="testimonials_home">
            <div>
                <h2 className="font-Quick font-semi">What Our Students Say</h2>
                <Box width="95%" maxWidth="1150px" margin="auto">
                    <Slider {...settings}>
                        {testimonials.map((item, key) => (
                            <div className="testimonial_wrapper" key={key}>
                                <div>
                                    <div className="testimonial_content_wrapper">
                                        <div className="testimonial_image">
                                            <img
                                                src={item.img}
                                                alt={item.name}
                                            />
                                        </div>

                                        <h3 className="font-Quick font-semi">
                                            {item.name}
                                        </h3>
                                        <p className="font-Quick font-semi testimonial_role">
                                            {item.role}
                                        </p>
                                    </div>
                                    <p className="font-Quick font-med testimonial_saying">
                                        {item.saying}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </Box>
            </div>
        </div>
    )
}
