import { Container } from '@material-ui/core'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useAuthContext } from '../contexts/AuthContext'
import CustomDialog from '../partialComponents/CustomDialog'

function AdminPanelTemplate({ children }) {
    const { logout } = useAuthContext()
    const history = useHistory()

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <PanelContainer>
            <LogoutButton onClick={handleClickOpen}>Logout</LogoutButton>
            <GoToHome
                onClick={() => {
                    history.push('/admin')
                }}
            >
                Home
            </GoToHome>
            <Container>{children}</Container>

            <CustomDialog
                title={'Are you Sure ?'}
                description={'You will be logged out from admin panel...'}
                successText={'Logout'}
                cancelText={'Cancel'}
                successFunc={logout}
                open={open}
                handleClose={handleClose}
            />
        </PanelContainer>
    )
}

const PanelContainer = styled.div`
    min-height: 100vh;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
    width: 100%;
    background-color: #f2f4fc;
    font-family: 'Quicksand', sans-serif;
    position: relative;
`

const Button = styled.button`
    position: absolute;
    top: 1rem;

    background-color: #9ba4cb;
    outline: none;
    border: none;
    color: #fff;
    width: 100%;
    max-width: 120px;
    padding: 12px 20px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform ease-in-out 0.15s;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-weight: 600;

    &:hover {
        transform: scale(1.02);
    }
    &:active {
        transform: scale(0.98);
    }
`

const LogoutButton = styled(Button)`
    right: 1rem;
`
const GoToHome = styled(Button)`
    right: calc(2rem + 120px);
`

export default AdminPanelTemplate
