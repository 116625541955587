import { Grid } from '@material-ui/core'
import btechWave2 from '../../Assets/layout_images/btechWave2.png'

export default function WhoCanBenefitHeptagon({
    waveTop,
    page,
    whoCanBenefits
}) {
    return (
        <div
            className={`${page}_who_can_benefit_section who_can_benefit_section_general`}
        >
            <h2 className="font-Quick font-semi">
                Who can benefit from TeacherInd?
            </h2>
            <img
                className={`${page}_wave1 wave1_general`}
                src={waveTop}
                alt="wave1"
            />

            <div
                className={`content_who_can_benefit_${page} content_who_can_benefit_general`}
            >
                <Grid container justify="center" spacing={3}>
                    {whoCanBenefits.map((el, key) => (
                        <Grid item xs={6} sm={4} md={3} key={key}>
                            <div
                                className={`${page}_benef_hept_card general_benef_hept_card`}
                            >
                                <h4 className="font-Rale font-bold">
                                    {key + 1 < 10 ? `0${key + 1}` : key + 1}
                                </h4>
                                <p className="font-Quick font-bold">{el}</p>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
            <img
                className={`${page}_wave2 general_wave2`}
                src={btechWave2}
                alt="wave2"
            />
        </div>
    )
}
