export const helmets = {
    spokenEnglish: {
        title: 'Speak English with freedom.',
        description:
            'Speak english fluently with an indivdual mentor. You don’t have to hear about your silly english mistakes as you can communicate your worries directly to your personal tutor. Develop passion and skill in English language.',
        keywords:
            'online tuition, online teacher, online tutor, online tutoring, online teaching, online coaching classes, live tutoring, doubt solving, Live tuitions, Personal Tutors, online tutoring companies, spoken English classes',
        canonical: 'https://teacherind.com/spoken-english'
    },
    btech: {
        title: 'Online Tuition with Personal Teachers for B.Tech students.',
        description:
            'Get Individual tuition on the Best tutoring platform with Expert tutors for B.tech subjects. Get all your doubts solved and get good grades for all your difficult subjects. Get Module-wise tuitions with reasonable fees.',
        keywords:
            'online tuition, online teacher, online tutor, online tutoring, online teaching, online coaching classes, homework help, live tutoring, doubt solving, Live tuitions, Personal Tutors',
        canonical: 'https://teacherind.com/btech'
    },
    cbse: {
        title: 'Online Live Tuitions for CBSE/ICSE Syllabus',
        description:
            'We provide Online Individual tuition for CBSE/ICSE/IGCSE Syllabus.Solve all your doubts and get support for all your difficult subjects. Learn at the comfort of your language and time.',
        keywords:
            'online tuition, online teacher, online tutor, online tutoring, online teaching, online coaching classes, homework help, live tutoring, doubt solving, Live tuitions, Personal Tutors, online tutoring companies, best online tutoring for CBSE/ICSE/IGCSE, CBSE/ICSE Syllabus, online tutoring websites, tutorial sites, live tutor, live math tutor, Private Tutors, Tuitions in India, online tutoring sites, best online tutoring sites, CBSE/ICSE/IGCSE syllabus, NCERT.',
        canonical: 'https://teacherind.com/cbse/'
    },
    state: {
        title: 'Online Live Tuitions for State Syllabus',
        description:
            'We provide Online Individual tuition for Kerala State students.Solve all your doubts and get support for all your difficult subjects. Learn at the comfort of your language and time.',
        keywords:
            'online tuition, online teacher, online tutor, online tutoring, online teaching, online coaching classes, homework help, live tutoring, doubt solving, Live tuitions, Personal Tutors, online tutoring companies, best online tutoring in Kerala, Kerala Syllabus, online tutoring websites, tutorial sites, live tutor, live math tutor, Private Tutors, Tuitions in India, online tutoring sites, best online tutoring sites, SCERT syllabus, Kerala State Board Text Books.',
        canonical: 'https://teacherind.com/state/'
    },
    robotics: {
        title: 'Learn robotics online.',
        description:
            'If you have a passion for Robotics, TeacherInd is the platform for you. We provide practical learning, hands-on-experience and useful kits to enjoy your craze for robotics. Our experienced tutors can teach you all the basics of robotics as well as help you create your robot.',
        keywords:
            'online tuition, online teacher, online tutor, online tutoring, online teaching, online coaching classes, homework help, live tutoring, doubt solving, Live tuitions, Personal Tutors, online tutoring companies, best online tutoring for robotics, robots, Kit for robots, practical learning, create your robot, skill development.',
        canonical: 'https://teacherind.com/robotics/'
    },
    home: {
        title: 'Online Individual Tuition for CBSE, ICSE, B.tech and Robotics',
        description:
            'We provide Online Individual tuition for ICSE, State, CBSE and B. Tech students.Solve all your doubts and get good grades for all your difficult subjects. We provide a personal tutor for each student. Experience our service through a FREE Demo class.',
        keywords:
            'online tuition, online teacher, online tutor, online tutoring, online teaching, online coaching classes, homework help, live tutoring, doubt solving, Live tuitions, Personal Tutors, online tutoring companies, best online tutoring in Kerala, online tutoring websites, tutorial sites, live tutor, live math tutor, Private Tutors, ICSE Tutor and Tuitions in India, online tutoring sites, best online tutoring sites',
        canonical: 'https://teacherind.com/'
    }
}
