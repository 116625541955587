import { Container, Hidden } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import cbseHero from '../../Assets/layout_images/cbseHero.svg'
import stateHero from '../../Assets/layout_images/stateHero.svg'
import CustomContainer from './CustomContainer'

export default function StateCbseHero({ cbse }) {
    const location = useLocation()

    function pageIdentifier() {
        let details = {
            mainPath: '',
            subPath: ''
        }
        const pathnameList = location.pathname.split('/')

        const mainPath = pathnameList[1]
        const subPath = pathnameList[2]

        const mainPathLists = {
            cbse: 'CBSE',
            igcse: 'IGCSE',
            state: 'Kerala State',
            icse: 'ICSE'
        }
        const subPathLists = {
            class1: '1',
            class2: '2',
            class3: '3',
            class4: '4',
            class5: '5',
            class6: '6',
            class7: '7',
            class8: '8',
            class9: '9',
            class10: '10',
            class11: '11',
            class12: '12',
            'class1-7': '1-7',
            'class8-10': '8-10',
            'class11-12': `11 & 12`
        }

        for (let i = 0; i < Object.keys(mainPathLists).length; i++) {
            if (Object.keys(mainPathLists)[i] === mainPath) {
                details['mainPath'] = mainPathLists[mainPath]
                break
            }
        }

        for (let i = 0; i < Object.keys(subPathLists).length; i++) {
            if (Object.keys(subPathLists)[i] === subPath) {
                details['subPath'] = subPathLists[subPath]
                break
            }
        }

        return (
            <h1 className="font-Rale font-bold">
                {`Online Live Tuitions for `}
                {!!details['subPath'] && <span>{`Class `}</span>}
                <span className="font-Robo font-bold">{`${details['subPath']}`}</span>
                {!!details['subPath'] && ` of `}
                <span>{details['mainPath']}</span>
                {' Syllabus'}
            </h1>
        )
    }

    return (
        <div className="state_hero_section">
            <Container>
                <CustomContainer>
                    <div className="hero_state_wrapper">
                        <div className="hero_content_state">
                            {pageIdentifier()}
                            <p className="font-Quick font-reg">
                                Get good grades for every difficult subject.
                            </p>
                            <Hidden smDown>
                                <div className="state_button">
                                    <Link
                                        to="/form"
                                        className="state_book_button font-Quick font-bold"
                                    >
                                        Book A Free Class
                                    </Link>
                                </div>
                            </Hidden>
                        </div>
                        <div className="hero_image_section_state">
                            <img src={cbse ? cbseHero : stateHero} alt="hero" />
                        </div>
                    </div>
                </CustomContainer>
            </Container>
        </div>
    )
}
