import { Container, Hidden } from '@material-ui/core'
import { Link } from 'react-router-dom'
import CustomContainer from './CustomContainer'
import spokenHero from '../../Assets/layout_images/spokenHero.svg'
import tickIcon from '../../Assets/layout_icons/tickIcon.svg'

export default function SpokenHero() {
    return (
        <div className="spoken_hero_section">
            <Container>
                <CustomContainer>
                    <div className="hero_spoken_wrapper">
                        <div className="hero_content_spoken">
                            <h1 className="font-Rale font-xbold">
                                Speak English with freedom
                            </h1>
                            <div>
                                <p className="font-Quick font-reg">
                                    <img src={tickIcon} alt="tickIcon" />
                                    One student. One Teacher
                                </p>
                                <p className="font-Quick font-reg">
                                    <img src={tickIcon} alt="tickIcon" />
                                    100% Interactive classes
                                </p>
                            </div>
                            <Hidden smDown>
                                <div className="spoken_button">
                                    <Link
                                        to="/form"
                                        className="state_book_button font-Quick font-bold"
                                    >
                                        Book A Free Class
                                    </Link>
                                </div>
                            </Hidden>
                        </div>
                        <div className="hero_image_section_spoken">
                            <img src={spokenHero} alt="spokenHero" />
                        </div>
                    </div>
                </CustomContainer>
            </Container>
        </div>
    )
}
