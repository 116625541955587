import {
    Checkbox,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core'
import { tsToDate } from '../utils/tsToDate'
import styled from 'styled-components'
import CustomDialog from './CustomDialog'
import { useState } from 'react'
import { DB } from '../../firebase'

const useStyles = makeStyles({
    table: {
        minWidth: 1200
    }
})

function LeadTable({ leadData, tools = [] }) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [type, setType] = useState('')
    const [activeID, setActiveID] = useState('')

    const types = {
        COMPLETED: 'COMPLETED',
        SUCCESS: 'SUCCESS',
        REJECTED: 'REJECTED'
    }
    const typesToField = {
        COMPLETED: 'completed',
        SUCCESS: 'confirmed',
        REJECTED: 'rejected'
    }

    function getDate(item) {
        const date = tsToDate(item)
        return (
            <p>
                {date[0]}
                <br />
                <span style={{ color: '#706e6e' }}>{date[1]}</span>
            </p>
        )
    }

    function handleClose() {
        setOpen(false)
    }

    function handleUpdateDB(field) {
        if (!field) return

        const dbField = typesToField[field]

        DB.collection('leads')
            .doc(activeID)
            .set(
                {
                    [dbField]: true
                },
                { merge: true }
            )
            .then(() => {
                console.log('updated successfully')
            })
            .catch(() => {
                console.log('error occured')
            })

        handleClose()
    }

    const handleClickOpen = (type, id) => {
        setOpen(true)
        setType(type)
        setActiveID(id)
    }

    return (
        <>
            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{`Sl.No.`}</TableCell>
                            <TableCell>{`Date & Time`}</TableCell>
                            <TableCell>{'Name'}</TableCell>
                            <TableCell>{'Email'}</TableCell>
                            <TableCell>{'Phone No.'}</TableCell>
                            <TableCell>{'Program'}</TableCell>
                            <TableCell>{`Stream / Age / Class`}</TableCell>
                            {tools.includes('complete') && (
                                <TableCell>{'Completed'}</TableCell>
                            )}
                            {tools.includes('success') && (
                                <TableCell>{'Success'}</TableCell>
                            )}
                            {tools.includes('reject') && (
                                <TableCell>{'Rejected'}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leadData.map((row, key) => (
                            <TableRow key={key}>
                                <TableCell>{key + 1}</TableCell>
                                <TableCell>{getDate(row?.timestamp)}</TableCell>
                                <TableCell>{row?.name}</TableCell>
                                <TableCell>{row?.email}</TableCell>
                                <TableCell>{row?.phoneNumber}</TableCell>
                                <TableCell>{row?.program}</TableCell>
                                <TableCell>{row?.subProgram}</TableCell>
                                {tools.includes('complete') && (
                                    <TableCell>
                                        <Checkbox
                                            checked={row?.completed}
                                            onChange={() =>
                                                handleClickOpen(
                                                    types.COMPLETED,
                                                    row?.id
                                                )
                                            }
                                            color="secondary"
                                        />
                                    </TableCell>
                                )}
                                {tools.includes('success') && (
                                    <TableCell>
                                        <Checkbox
                                            checked={row?.confirmed}
                                            onChange={() =>
                                                handleClickOpen(
                                                    types.SUCCESS,
                                                    row?.id
                                                )
                                            }
                                            color="primary"
                                        />
                                    </TableCell>
                                )}
                                {tools.includes('reject') && (
                                    <TableCell>
                                        <Checkbox
                                            checked={row?.rejected}
                                            onChange={() =>
                                                handleClickOpen(
                                                    types.REJECTED,
                                                    row?.id
                                                )
                                            }
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <CustomDialog
                title={'Are you Sure ?'}
                description={`This student status will be modified as ${types?.[type]}...`}
                successText={'OK'}
                cancelText={'Cancel'}
                successFunc={!!type ? () => handleUpdateDB(type) : () => {}}
                open={open}
                handleClose={handleClose}
            />
        </>
    )
}

const TableContainer = styled.div`
    background-color: #fff;
    border: 1px solid #dddddd;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.07);
    border-radius: 15px;
    padding: 20px 10px;
    width: 100%;
`

export default LeadTable
