import styled from 'styled-components'
import AdminPanelTemplate from '../templates/AdminPanelTemplate'
import { Link } from 'react-router-dom'

function AdminPanel() {
    return (
        <AdminPanelTemplate>
            <Heading>Admin Panel</Heading>
            <Panel>
                <Link to="/admin/leads">View Leads</Link>
                <Link to="/admin/blogs/view">View Blogs</Link>
                <Link to="/admin/blogs/create">Create New Blogs</Link>
            </Panel>
        </AdminPanelTemplate>
    )
}

const Heading = styled.h1`
    color: #333;
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
    margin-top: 2rem;
`

const Panel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding-top: 2rem;

    > a {
        display: block;
        width: 100%;
        max-width: 350px;
        padding: 15px 20px;
        background-color: #7f87b2;
        margin: 15px auto;
        border-radius: 20px;
        color: #fff;
        text-align: center;
        text-decoration: none;
        transition: all ease-in-out 0.2s;
        font-weight: 600;

        &:hover {
            transform: scale(1.04);
            background-color: #636b96;
        }
        &:active {
            transform: scale(0.98);
        }
    }
`

export default AdminPanel
