import { Container, Grid } from '@material-ui/core'
import spokenWave1 from '../../Assets/layout_images/spokenWave1.png'
import spokenWave2 from '../../Assets/layout_images/spokenWave2.png'
import spokenWhoNeeds from '../../Assets/layout_images/spokenWhoNeeds.svg'
import CustomContainer from './CustomContainer'

export default function WhoNeedSpoken() {
    return (
        <div className="who_need_spoken_section">
            <img className="spokenWave1" src={spokenWave1} alt="spokenWave1" />
            <Container>
                <CustomContainer>
                    <div className="who_need_spoken_content">
                        <h2 className="font-Quick font-semi">
                            Who needs Spoken English?
                        </h2>
                        <div>
                            <div className="who_need_spoken_image">
                                <img
                                    src={spokenWhoNeeds}
                                    alt="spokenWhoNeeds"
                                />
                            </div>
                            <div className="who_need_spoken_points_wrapper">
                                <Grid container>
                                    {[
                                        'Those who require individual attention',
                                        'Those who need to sharpen their English skills',
                                        'Those who require affordable learning',
                                        'Those who need to improve their conversation'
                                    ].map((el, key) => (
                                        <Grid item xs={12} sm={6} key={key}>
                                            <div className="who_need_spoken_points">
                                                <p className="font-Rale font-xbold">
                                                    {key + 1}
                                                </p>
                                                <p className="font-Quick font-med">
                                                    {el}
                                                </p>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </div>
                    </div>
                </CustomContainer>
            </Container>
            <div className="spokenWave2">
                <img src={spokenWave2} alt="spokenWave2" />
            </div>
        </div>
    )
}
