import FaqContainer from '../partialComponents/FaqContainer'
import BasicTemplate from '../templates/BasicTemplate'
import { faqs } from '../utils/faqs'
import StateBenefits from '../partialComponents/StateBenefits'
import { benefits } from '../utils/benefits'
import StateCbseHero from '../partialComponents/StateCbseHero'
import StateCbseGrains from '../partialComponents/StateCbseGrains'
import HelmetGenerator from '../partialComponents/HelmetGenerator'
import { helmets } from '../utils/helmets'
import { useEffect } from 'react'
import GetStarted from '../partialComponents/GetStarted'
import stateWave from '../../Assets/layout_images/stateWave.png'
import { whoCanBenefit } from '../utils/whoCanBenefit'
import WhoCanBenefitHeptagon from '../partialComponents/WhoCanBenefitHeptagon'
import { useLocation } from 'react-router'

export default function Cbse() {
    const location = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])
    return (
        <>
            <HelmetGenerator details={helmets.cbse} />
            <BasicTemplate>
                <div className="state_page_section">
                    <StateCbseHero cbse={true} />

                    <StateCbseGrains />

                    <StateBenefits benefits={benefits.cbse} />
                    <WhoCanBenefitHeptagon
                        page={'state'}
                        waveTop={stateWave}
                        whoCanBenefits={whoCanBenefit.state}
                    />
                    <GetStarted type={'state'} color={'#4394F7'} />

                    <FaqContainer faqList={faqs.state} />
                </div>
            </BasicTemplate>
        </>
    )
}
