import { Grid } from '@material-ui/core'

export default function StateBenefitCard({ image, title, content }) {
    return (
        <Grid item xs={6} md={3}>
            <div className="benefit_card_state">
                <div className="image_benefit_card_state">
                    <img src={image} alt={title} />
                </div>
                <div className="content_benefit_card_state">
                    <h3 className="font-Quick font-semi">{title}</h3>
                    <p className="font-Quick font-med">{content}</p>
                </div>
            </div>
        </Grid>
    )
}
